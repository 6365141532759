import React, { useState, useEffect } from 'react';
import { IntercomProvider } from 'react-use-intercom';

const RootElementWrapper = ({ element }) => {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem('gh_hide_banner', false);
    }

    const [cookieConsentGranted, setCookieConsentGranted] = useState(false);

    useEffect(() => {
        const checkCookie = () => {
            let validCookieFound = false;
            const consent = document.cookie.split('; ').find(row => row.startsWith('CookieConsent='));
            if (consent) {
                const consentParts = consent.split('=');
                if (consentParts.length > 1) {
                    const value = consentParts[1];
                    if (value === 'true') {
                        setCookieConsentGranted(true);
                        validCookieFound = true;
                    } else if (value === 'false') {
                        setCookieConsentGranted(false);
                        validCookieFound = true;
                    }
                }
            }
            return validCookieFound;
        };

        // Check for the cookie immediately, then periodically until it's found
        let intervalId = null;
        if (!checkCookie()) {
            intervalId = setInterval(() => {
                if (checkCookie()) {
                    clearInterval(intervalId);
                }
            }, 2000);
        }

        // Clears the interval when the component is unmounted
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    return (
        cookieConsentGranted ? (
            <IntercomProvider autoBoot appId={process.env.GATSBY_INTERCOM_ID}>
                {element}
            </IntercomProvider>
        ) : (
            <>{element}</>
        )
    );
};

export const wrapRootElement = ({ element }) => <RootElementWrapper element={element} />;